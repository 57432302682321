const environments = {
  local: {
    // NOTE: if using a mobile emulator, then localhost is 10.0.2.2
    //amplifyApiHost: 'http://localhost:51185',
    amplifyApiHost: 'https://api.dev.alsac.stjude.org/integration-v1/amplify-portal-services',
    amplifyClientId: '0oacyh9tf2eKqR5cz297',
    amplifyClientSecret: '',

    publicUrl: '',
    oktaReturnUrl: 'http://localhost:3000/implicit/callback',
    appRootPath: '/',
    appStage: 'local',
  },
  dev: {
    amplifyApiHost: 'https://api.dev.alsac.stjude.org/integration-v1/amplify-portal-services',
    amplifyClientId: '0oacyh9tf2eKqR5cz297',
    amplifyClientSecret: '',

    publicUrl: 'https://amplify-dev.alsac.stjude.org/amplify-portal-client',
    oktaReturnUrl: 'https://amplify-dev.alsac.stjude.org/amplify-portal-client/implicit/callback',
    appStage: 'dev',
  },
  qa: {
    amplifyApiHost: 'https://api.qa.alsac.stjude.org/integration-v1/amplify-portal-services',
    amplifyClientId: '0oacyhc8j4jcuULsa297',
    amplifyClientSecret: '',

    publicUrl: 'https://amplify.qa.alsac.stjude.org/amplify-portal-client',
    oktaReturnUrl: 'https://amplify.qa.alsac.stjude.org/amplify-portal-client/implicit/callback',
    appStage: 'qa',
  },
  prod: {
    amplifyApiHost: 'https://api.alsac.stjude.org/integration-v1/amplify-portal-services',
    amplifyClientId: '0oacyhbi7yIUV6Mav297',
    amplifyClientSecret: '',

    oktaIssuer: 'https://alsac.okta.com/oauth2/aus5ktzayqDq3F4dl297',
    publicUrl: 'https://amplify.alsac.stjude.org/amplify-portal-client',
    appClientId: '0oa5ktt014cDxAf4t297',
    oktaReturnUrl: 'https://amplify.alsac.stjude.org/amplify-portal-client/implicit/callback',
    appStage: 'prod',
  },
};

const commonConfig = {
  oktaIssuer: 'https://stjude.oktapreview.com/oauth2/ausray07w3ugdvdZk0h7',
  appClientId: '0oar4f022inrIX5k60h7',
  appRootPath: '/amplify-portal-client/',
};

interface ConfigType {
  amplifyApiHost: string;
  amplifyClientId: string;
  amplifyClientSecret: string;

  oktaIssuer: string;
  appClientId: string;
  oktaReturnUrl: string;
  appRootPath: string;
  publicUrl: string;
  appStage: string;
}

function getStage() {
  switch (process.env.REACT_APP_STAGE) {
    case 'prod':
      return 'prod';
    case 'qa':
      return 'qa';
    case 'dev':
      return 'dev';
    case 'local':
      return 'local';
    default:
      return 'dev';
  }
}

const env = getStage();

const config: ConfigType = {
  ...commonConfig,
  ...environments[env],
};

export default config;
