/* eslint-disable @typescript-eslint/indent */
import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter, Route, useHistory, Switch, useLocation, Redirect } from 'react-router-dom';
import { Security, SecureRoute, LoginCallback, useOktaAuth } from '@okta/okta-react';
import { SubApp, AmplifyPoco } from '@amplify/amplify-sdk';
import { Col, Container, Row } from 'reactstrap';
import config from '../Config';
import oktaConfig from '../okta-config';
import AppDetails from './AppDetails';
import AdminLayout from './Shared/AdminLayout';
import { loadAppConfigs, onAmplifyApps, setOktaToken, urlHistory } from '../services/SubAppLoader';
import { Versions } from './Shared/Versions';
import { JwtToken } from '../services/Types.js';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import axios from 'axios';

const { REACT_APP_STAGE, REACT_APP_CDN_HOST } = process.env;

// const $username = new BehaviorSubject<string>('');
// export let onUsername = () => $username.asObservable() as Observable<string>;

function HasAccessToRouter() {
  const { authState, authService, oktaAuth } = useOktaAuth();
  const history = useHistory();
  const [subApps, setSubApps] = useState<SubApp[]>([]);
  const [admin, setAdmin] = useState(false);
  const [token, setToken] = useState<string>();

  useEffect(() => {
    const $subApps = onAmplifyApps().subscribe(items => {
      setSubApps([...items]);
    });
    return () => {
      $subApps.unsubscribe();
    };
  }, []);

  const storeIntendedRoute = () => {
    const existingPath = localStorage.getItem('intendedRoute');
    if (history.location.pathname !== '/implicit/callback' && history.location.pathname !== existingPath) {
      localStorage.setItem('intendedRoute', history.location.pathname);
    }
  };

  const restoreIntendedRoute = () => {
    const intendedRoute = localStorage.getItem('intendedRoute') || '/';
    if (intendedRoute) {
      history.push(intendedRoute);
    }
  };

  useEffect(() => {
    storeIntendedRoute();
  }, [history.location.pathname]);

  useEffect(() => {
    if (authState?.isAuthenticated && authState?.accessToken) {
      const isLoggedIn = window.__IS_LOGGED_IN__;
      const token = authState.accessToken.accessToken;
      setOktaToken(token);
      if (!isLoggedIn) {
        window.__IS_LOGGED_IN__ = true;
        window.amplify.axios.post('/reporting/logins');
      }
    } else {
      oktaAuth.tokenManager.get('accessToken').then(function (oktaToken: any) {
        // eslint-disable-next-line no-debugger
        if (!oktaToken && !token && history.location.pathname !== '/implicit/callback') {
          const baseURL = `${window.location.origin}/${AppDetails.path}`;
          let replaceUrl = toRelativeUrl(window.location.href || '/', baseURL);
          console.log(baseURL);
          console.log(AppDetails.path);
          console.log(replaceUrl);
          let matchTextUrl: RegExpMatchArray | null = replaceUrl.match(/amplify-portal-client/g);
          if (replaceUrl && matchTextUrl != null && matchTextUrl?.length > 1) {
            replaceUrl = replaceUrl.replace('amplify-portal-client/', '');
          }
          oktaAuth.setOriginalUri(replaceUrl);
          oktaAuth.signInWithRedirect();
        } else if (oktaToken === undefined) {
          //localStorage.clear();
          if (history.location.pathname === '/implicit/callback') {
            oktaAuth.tokenManager.renew('accessToken');
            restoreIntendedRoute();
          } else {
            oktaAuth.signInWithRedirect();
          }
        } else if (oktaToken && !token) {
          setToken(oktaToken.accessToken.accessToken);
          const jwt: JwtToken = JSON.parse(atob(oktaToken.accessToken?.split('.')[1]));
          var adm = jwt?.Roles?.some(r => r.toLowerCase().endsWith('-admin'));
          if (adm) setAdmin(adm);
          if (jwt) {
            window.__IS_LOGGED_IN__ = true;
            restoreIntendedRoute();
            //history.push('/apps/dashboard');
          }
        }
      });
    }
  }, [authState?.isAuthenticated, authState?.accessToken, authState, authService]);

  useEffect(() => {
    const token = authState?.accessToken?.accessToken || '';
    if (token) {
      if (!window.amplify.axios) {
        console.log(window.amplify.axios);
        console.log('creating axios instance');
        let defaultOptions = {
          baseURL: `${config.amplifyApiHost}/v1/`,
          headers: {
            Accept: 'text/plain',
            'Content-Type': 'application/json-patch+json',
            client_id: `${config.amplifyClientId}`,
          },
        };

        window.amplify.axios = axios.create(defaultOptions);
        window.amplify.token = token;
        try {
          const jwt: JwtToken = JSON.parse(atob(token.split('.')[1]));
          window.amplify.roles = jwt.Roles;
        } catch {
          /* empty */
        }
      }
      window.amplify.axios
        .get<AmplifyPoco[]>('/apps', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(
          (result: any) => {
            loadAppConfigs(result.data);
          },
          (error: any) => {
            console.log(error);
            window.amplify.urlHistory.push('/login');
          }
        );
    }
  }, [authState?.accessToken?.accessToken]);

  function NoMatch() {
    const location = useLocation();

    console.log(`No match for ${location.pathname}`);

    if (subApps.length) {
      return <Redirect to="/" />;
    }
    return <></>;
  }

  function getConfig() {
    return (
      <div>
        <span>Stage : {REACT_APP_STAGE} </span>
        <br />
        <span>CDN Host : {REACT_APP_CDN_HOST} </span>
        <br />
        <span>Amplify Api Host : {config.amplifyApiHost} </span>
        <br />
        <span>Amplify Client Id : {config.amplifyClientId} </span>
        <br />
        <span>Okta Client Id : {oktaConfig.oidc.clientId} </span>
        <br />
        <span>Okta Issuer : {oktaConfig.oidc.issuer} </span>
        <br />
        <br />
        <span>Okta Redirect Uri : {oktaConfig.oidc.redirectUri} </span>
        <br />
      </div>
    );
  }
  const getLogin = () => {
    return <Redirect to="/" />;
  };

  let containerStyle: any = {};

  return (
    <Switch>
      <Route path="/version" exact component={Versions} />
      <Route path="/config" exact component={getConfig} />
      <Route path="/implicit/callback" component={LoginCallback} />
      <Route path="/login" component={getLogin} />
      <Route path="/apps/login" component={getLogin} />

      <AdminLayout admin={admin}>
        <Container fluid className="pt-3" style={containerStyle}>
          <Row>
            <Col>
              <Route exact path="/">
                <Redirect to="/apps/dashboard" />
              </Route>
              {subApps.map((subApp: SubApp, index: number) => (
                <SecureRoute key={index} path={subApp.path} component={subApp.subApp} />
              ))}
            </Col>
          </Row>
        </Container>
      </AdminLayout>
      <Route path="*">
        <NoMatch />
      </Route>
    </Switch>
  );
}
const oktaAuth = new OktaAuth(oktaConfig.oidc);

export function SecurityGuard() {
  const history = useHistory();
  urlHistory.setHistoryInstance(history);

  const restoreOriginalUri = useCallback((oktaAuth: OktaAuth, originalUri: string) => {
    history.replace(originalUri);
  }, []);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <HasAccessToRouter />
    </Security>
  );
}

export default function App() {
  return (
    <BrowserRouter basename={`/${AppDetails.path}`}>
      <SecurityGuard />
    </BrowserRouter>
  );
}
