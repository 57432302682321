import React from 'react';
import { Row, Col } from 'reactstrap';

export default function Footer() {
  return (
    <footer className="footer">
      <Row className="align-items-center justify-content-xl-between">
        <Col>
          <div className="copyright text-center text-xl-left text-muted">
            © Copyright 2022. St. Jude Children&apos;s Research Hospital, a not-for-profit, section 501(c)(3).
          </div>
        </Col>
      </Row>
    </footer>
  );
}
