import appConfig from './Config';

export default {
  oidc: {
    clientId: appConfig.appClientId || '',
    issuer: appConfig.oktaIssuer || '',
    redirectUri: appConfig.oktaReturnUrl || '',
    pkce: true,
    scopes: ['openid', 'profile', 'email', 'phone',],
    tokenManager: {
      autoRenew: true,
    },
  },
};
