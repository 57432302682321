/* eslint-disable no-debugger */
import { MicroApp } from './services/Types';



declare global {
  interface Window {
    [key: string]: (appName: string) => void;
  }
}

export function appendAppToDom(app: MicroApp) {
  const { slug , moduleName} = app;
  const scriptId = `micro-frontend-subapp--${slug}`;

  if (document.getElementById(scriptId)) {
    renderApp(app);
    return;
  }

  const host = `${process.env.REACT_APP_CDN_HOST}/apps/${slug}`;
  var cachebuster = Math.round(new Date().getTime() / 1000);

  const script = document.createElement('script');
  script.id = scriptId;
  script.crossOrigin = '';
  console.debug(`appendAppToDom Config: ${script.id}`);
  // if (script.id.includes('staff'))
  script.type = 'module';
  script.src = `${host}/static/js/${moduleName}.js?v=${cachebuster}`;
  script.onload = () => {
    renderApp(app);
  };
  document.head.appendChild(script);
  console.debug(`appendAppToDom Done: ${script.id}`);
}

export function removeAppFromDom(app: MicroApp) {
  const { slug, functionName } = app;
  const unmountFunction = window[`unmount${functionName}`];
  if (unmountFunction !== undefined) {
    unmountFunction(getAppId(slug));
  }
}

function renderApp(app: MicroApp) {

  const { slug, functionName } = app;

  const renderFunctionName = `render${functionName}`;
  const renderFn = window[renderFunctionName];
  if (renderFn !== undefined)
    renderFn(getAppId(slug));

}

export function getAppId(slug: string) {
  return `subapp--${slug}`;
}
