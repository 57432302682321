import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { AmplifyApp, SubApp } from '@amplify/amplify-sdk'
import { appendAppToDom, removeAppFromDom, getAppId } from './micro-frontend';

export function InitSubApp(app: AmplifyApp) : SubApp{
  return {
    ...app,
    path: `/apps/${app.slug}`,
    subApp: () => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { authState } = useOktaAuth();

      function logVisit(token: string) {
        window.amplify.axios.post('/reporting/visits-slug', JSON.stringify(app.slug));
      }
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        // exit until dom is drawn
        if(document.getElementById(getAppId(app.slug))){
          console.debug(`SubApp: Loading ${app.slug}`)
          appendAppToDom(app);
          logVisit(authState.accessToken);
          console.debug(`SubApp: Loading Done ${app.slug}`)
        }
        return function cleanup(){
          console.debug(`SubApp: Removing ${app.slug}`)
          removeAppFromDom(app);
        }
      },[]) // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <div id={getAppId(app.slug)} className={`${app.slug}-container`} />
      )
    }
  }
}



