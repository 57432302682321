import { Version } from '@amplify/amplify-sdk';
import React, { useEffect, useState } from 'react';
import { onVersions } from '../../services/SubAppLoader';

interface AdditionalInfoProps {
  iterable: Map<string, string>;
}
export function Versions() {
  const [versions, setVersions] = useState<Version[]>([]);

  useEffect(() => {
    const $versions = onVersions().subscribe(items => {
      if (items.length > 0) {
        setVersions([...items]);
      }
    });
    return () => {
      $versions.unsubscribe();
    };
  }, []);

  const AdditionalInfo = (props: AdditionalInfoProps) => {
    const { iterable } = props;
    const array = Array.from(iterable || [], ([name, value]) => ({ name, value }));
    return (
      <>
        {array.map(x => (
          <div className="ml-6">
            {x.name}: {x.value}
          </div>
        ))}
      </>
    );
  };

  return (
    <div>
      {versions.map(version => (
        <div>
          <h3 className="pl-4" style={{ backgroundColor: 'grey', color: 'white' }}>
            {version.name}
          </h3>
          <div className="ml-6">Author: {version.author}</div>
          <div className="ml-6">Version: {version.version}</div>
          <div className="ml-6">Commit Hash: {version.commitHash}</div>
          <div className="ml-6">Released: {version.releaseDate}</div>
          <AdditionalInfo iterable={version.additionalInfo} />
        </div>
      ))}
    </div>
  );
}
