import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
interface Props {
  isOpen: boolean;
}
export default function NotificationDialog(props: Props) {
  const [modal, setModal] = useState(false);
  const togglePopup = () => {
    console.log(props.isOpen);
    setModal(!modal);
  };
  useEffect(() => {
    console.log(props.isOpen);
    if (props.isOpen) setModal(true);
  }, [props.isOpen]);
  return (
    <Modal isOpen={modal} toggle={togglePopup}>
      <ModalBody>
        User has no permissions assigned. Please contact your app administrator, or the Help Desk at (901)578-2222.
      </ModalBody>
      <ModalFooter>
        <Button variant="secondary" onClick={togglePopup}>
          Ok
        </Button>
      </ModalFooter>
    </Modal>
  );
}
