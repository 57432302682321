/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import AdminFooter from './Footer';

import { Component, ComponentType } from '@amplify/amplify-sdk';
import { onComponentApps } from '../../services/SubAppLoader';
import { NavLink } from 'react-router-dom';
import NotificationDialog from './NotificationDialog';
interface Props {
  children: React.ReactNode;
  admin: boolean;
}

export default function AdminLayout(props: Props) {
  const [components, setComponents] = useState<Component[]>([]);
  const [navSlug, setNavSlug] = useState<string>('');
  const [navCoreSlug, setNavCoreSlug] = useState<string>('');
  const [navNotificationSlug, setNavNotificationSlug] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  // const [count, setCount] = useState(0);
  useEffect(() => {
    let count = 0;
    const $components = onComponentApps().subscribe(items => {
      count++;
      setIsOpen(false);
      if (items.length > 0) {
        setComponents([...items]);
      }

      if (count === 3 && items.length === 0) setIsOpen(true);
    });
    return () => {
      $components.unsubscribe();
    };
  }, []);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document?.scrollingElement?.scrollTop) {
      document.scrollingElement.scrollTop = 0;
    }
  });

  useEffect(() => {
    let navigation: Component | null = null;
    let coreNavigation: Component | null = null;
    let notifications: Component | null = null;
    if (!navigation && components.find(x => x.type === ComponentType.navigation)) {
      navigation = components.find(x => x.type === ComponentType.navigation) as Component;
      navigation.mount('Navigation');
      setNavSlug(`${navigation.slug}-container`);
    }
    if (!coreNavigation && components.find(x => x.type === ComponentType.coreNavigation)) {
      coreNavigation = components.find(x => x.type === ComponentType.coreNavigation) as Component;
      coreNavigation.mount('CoreNavigation');
      setNavCoreSlug(`${coreNavigation.slug}-container`);
    }
    if(!notifications && components.find(x => x.type === ComponentType.notification)) {
      notifications = components.find(x => x.type === ComponentType.notification) as Component;
      notifications.mount('Notifications');
      setNavNotificationSlug(`${notifications.slug}-container`);
    }
    return () => {
      if (!!navigation) navigation.unmount('Navigation');
      if (!!coreNavigation) coreNavigation.unmount('CoreNavigation');
      if (!!notifications) notifications.unmount('Notifications')
    };
  }, [components]);

  const logo = {
    innerLink: '/',
    imgSrc: require('../../assets/img/brand/st-jude-logo.png'),
    imgAlt: '...',
  };
  return (
    <>
      <div className="main-content admin-layout">
        <nav className="pb-2 pt-2 mb-4 card-header position-relative">
          <div id="Nav-Grid">
            <div id="Navigation" className={navSlug} />
            <div id="CoreNavigationGrid">
              <div id="CoreNavigation" className={navCoreSlug} />
              <div id="Notifications" className={navNotificationSlug} />
            </div>
          </div>
          <div id="Logo-Container">
            <div id="Logo-Image">
              <NavLink to={logo.innerLink}>
                <img alt={logo.imgAlt} src={logo.imgSrc} width="100%" />
              </NavLink>
            </div>
          </div>
        </nav>
        {props.children}
        <Container fluid>
          <NotificationDialog isOpen={isOpen} />
          <AdminFooter />
        </Container>
      </div>
    </>
  );
}
